"use client";

import { motion } from "framer-motion";
import axios from "axios";
import "./faq.css";
import { message } from "antd";
import { useEffect, useState } from "react";

const Faq = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [faq, setFaq] = useState<any>([]);
  const url = "https://b3nar.xyz.co.id/api/faq";

  useEffect(() => {
    getAllFaq();
  }, []);

  function getAllFaq() {
    axios
      .get(`${url}`)
      .then(function (response) {
        console.log(response);
        const allFaq = response.data.data;
        setFaq(allFaq);
        messageApi.open({
          type: "success",
          content: "Berhasil",
        });
      })
      .catch(function (error) {
        console.log(error);
        messageApi.open({
          type: "error",
          content: error.response?.data?.userMessage ?? "Gagal ",
        });
      });
  }
  return (
    <>
      <section className="pb-12.5 pt-32.5 lg:pb-25 lg:pt-45 xl:pb-30 xl:pt-5">
        <div className="mx-auto max-w-c-1235 px-4 md:px-8 xl:px-0">
          <div className="absolute left-0 top-0 -z-1 h-2/3 w-full rounded-lg bg-gradient-to-t from-transparent to-[#dee7ff47]"></div>
          <div className="absolute bottom-17.5 left-0 -z-1 h-1/3 w-full">
            <img src="/images/shape-dotted-light.svg" alt="Dotted" />
          </div>

          <motion.div
            variants={{
              hidden: {
                opacity: 0,
                y: -20,
              },

              visible: {
                opacity: 1,
                y: 0,
              },
            }}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 1, delay: 0.1 }}
            viewport={{ once: true }}
            className="animate_top rounded-lg bg-white px-7.5 pt-7.5 pb-7.5 shadow-solid-8 xl:px-15 xl:pt-15"
          >
            <h2 className="mb-10 text-center text-3xl font-semibold text-black l:text-sectiontitle2">Frequently Asked Question</h2>
            {faq.map((faq: any) => (
              <div>
                <h3 className="mb-0.5 mt-6 text-metatitle2 text-black">● {faq.question}</h3>
                <p>
                  <span dangerouslySetInnerHTML={{ __html: faq.answer }}></span>
                </p>
              </div>
            ))}
          </motion.div>
        </div>
      </section>
    </>
  );
};
export default Faq;
