import { motion } from "framer-motion";

function Home() {
  return (
    <>
      <section className="overflow-hidden pb-20 pt-35 md:pt-40 xl:pb-30 xl:pt-25">
        <div className="mx-auto max-w-c-1390 px-4 md:px-8 2xl:px-0">
          <motion.div
            variants={{
              hidden: {
                opacity: 0,
                y: -20,
              },

              visible: {
                opacity: 1,
                y: 0,
              },
            }}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 1, delay: 0.1 }}
            viewport={{ once: true }}
            className="animate_top"
          >
            <div className="flex lg:items-center lg:gap-8 xl:gap-32.5">
              <div className=" md:w-1/2">
                {/* <h4 className="mb-4.5 text-lg font-medium text-black ">🔥 Bebas Ongkir, Untung Berkali-kali</h4> */}
                <h1 className="mb-5 pr-16 text-3xl font-bold text-black  xl:text-hero ">TANQ</h1>
                <p>Pesan tangki tidak pernah lebih mudah dengan TANQ</p>

                <div className="mt-10">
                  <div className="flex flex-wrap gap-5">
                    <a href="https://play.google.com/store/apps/details?id=id.kalanusa.tangki" target="_blank">
                      <img src="/images/google-play.png" alt="shape" width={164} height={246} />
                    </a>
                    <a href="https://apps.apple.com/id/app/tanq/id6459403286" target="_blank">
                      <img src="/images/apps-store.svg" alt="shape" width={146} height={246} className="mt-2"/>
                    </a>
                  </div>
                  <p className="mt-5 text-black ">Coba sekarang juga !</p>
                </div>
              </div>

              <div className="animate_right hidden md:w-1/2 lg:block">
                <div className="relative 2xl:-mr-7.5">
                  <img src="/images/shape-01.png" alt="shape" width={46} height={246} className="absolute -left-11.5 top-0" />
                  <img src="/images/shape-02.svg" alt="shape" width={36.9} height={36.7} className="absolute bottom-0 right-0 z-10" />
                  <img src="/images/shape-03.svg" alt="shape" width={21.64} height={21.66} className="absolute -right-6.5 bottom-0 z-1" />
                  <div className=" relative aspect-[700/444] w-full">
                    <img src="/images/tanq.png" alt="Hero" width={608.4} height={440.7} />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </>
  );
}

export default Home;
