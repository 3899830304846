import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import Home from "./pages/Home/home";
import Layout from "./components/Layout/layout";
import Privacy from "./pages/Privacy/privacy";
import Account from "./pages/Account/deleteAccount";
import Terms from "./pages/Terms/terms";
import Faq from "./pages/Faq/faq";
import AccountProv from "./pages/Account/deleteAccountProv";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="faq" element={<Faq />} />
          <Route path="deleteAccount" element={<Account />} />
          <Route path="deleteAccountProv" element={<AccountProv />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
